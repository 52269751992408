.animated-ellipsis::after {
  content: '...';
  animation: ellipsis 1.5s infinite;
  display: inline-block;
  width: 20px;
  text-align: left;
}

@keyframes ellipsis {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}